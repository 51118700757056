import React from "react";
import imgUrl from "../images/landing-page/medical-team.png";
import ellipseUrl from "../images/landing-page/top-ellipse.png";
import {FaHandPointRight} from "react-icons/fa"


const Problem = () => {

    return (
        <div className="medical-team-wrapper">
        <article className="medical-team-box">
            <div className="medical-team-img">
                <img src={imgUrl} alt="medical-team" />
            </div>

            <div className="medical-team-text">
                <h2>The Problem</h2>
                <p> The healthcare sector in the country has numerous challenges, but here a few:</p>
                <br />
                <ul className="problem-list">
                    <li>
                        <FaHandPointRight /> Patients rarely get complete care.
                    </li>
                    <li>
                        <FaHandPointRight /> Overload of the available resources.
                    </li>
                    <li>
                        <FaHandPointRight />   Massive exit of healthcare professionals to other countries.
                    </li>
                    <li>
                        <FaHandPointRight /> Unmotivated professionals.
                    </li>
                </ul>
            </div>
            <img className="top-ellipse" src={ellipseUrl} alt="ellipse" />
        </article>
        </div>
    )
}

export default Problem;