import clinic from "./images/landing-page/vision-clinic.png";
import nurse from "./images/landing-page/vision-nurse.png";
import stethoscope from "./images/landing-page/vision-stethoscope.png"


/* Landing page data */
let visions = [
        {
            icon : clinic,
            content : "Gaps in hospital care can be filled by qualified professionals right from the comfort of your home."
        },
        {
            icon : stethoscope,
            content : "Time is an invaluable resource. Save time by making healthcare come to you."
        },
        {
            icon : nurse,
            content : "Staffing is a hassle. Find the best fit candidates in minutes. Run your business."
        },
    ]

let categories = {
    heading : "Categories",
    links : [
        {
            url : "#",
            text : "nursing"
        },

        {
            url : "#",
            text : "medical doctor"
        },

        {
            url : "#",
            text : "anesthiosiology"
        },

        {
            url : "#",
            text : "medical lab scientist"
        }
    ]
}

let support = {
    heading : "Support",
    links : [
        {
            url : "#",
            text : "help and support"
        },

        {
            url : "#",
            text : "trust and safety"
        }
    ]
}

let about = {
    heading : "About us",
    links : [
        {
            url : "mailto:keerhealthapp@gmail.com",
            text : "Contact us"
        }
    ]
}

export { visions, categories, support, about };