import React from "react";
import { FaTwitter } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { SiInstagram } from "react-icons/si";

const Social = () => {

    return (
        <>
            <a href="https://instagram.com/keer_health?igshid=YmMyMTA2M2Y="><SiInstagram className="social-icon instagram" /></a>
            <a href="https://www.linkedin.com/company/keerhealth/"><RiLinkedinFill className="social-icon linkedin" /></a>
            <a href="https://twitter.com/keer_app?t=fZ94Z7dUUZAHm-UsizRWNA&s=09"><FaTwitter className="social-icon twitter" /></a>      
        </>
    )
}

export default Social;