import React from "react";
import { v4 as key } from "uuid";

const Sublinks = ({heading, links}) => {
     return (
        <div className="sublink">
            <h4 className="footer-links-heading">{heading}</h4>
            { links.map(link => (
                <p key={key()}><a href={link.url} key={key()}>{link.text}</a></p>
            ))}    
        </div>
     )
}

export default Sublinks;