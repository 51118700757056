import React from "react";
import logo from "../images/global/logo.png";

const Logo = () => {

    return (
        <img className="brand-logo" src={logo} alt="logo" />
    )
}

export default Logo;