const Waitlist = () => {
	

	return (
		<div className="waitlist-box">
			<h3 className="waitlist-heading">Join the waitlist</h3>
			<div className="waitlist-form">
				<a href="https://forms.gle/AL7U2p2Q1Uf5eguq8">
					<button
					className="waitlist-btn">Join
					</button>
				</a>
			</div>
		</div>
	);
};

export default Waitlist;


