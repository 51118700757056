import React from "react";
import manSearchingJobUrl from "../images/landing-page/man-searching-jobs.png"
import ellipseUrl from "../images/landing-page/bottom-ellipse.png";

const Solution = () => {

    return (
        <div className="solution-wrapper">
        <div className="solution-box">
            <div className="solution-box-img">
            <img src={ellipseUrl} alt="bottom-ellipse" />
            </div>
            <div className="solution-text">
                <h2>The Solution</h2>
                <p>We are building keer!</p>
                <p>Keer allows you to find the best healthcare talent in the country. 
                Hire them for whatever purpose, and for whatever duration you like. 
                We handle the rest.
                </p>    
            </div>

            <div className="solution-img">
                <img src={manSearchingJobUrl} alt="man-searching-jobs-online" />
            </div>
        </div>
        </div>
    )
}

export default Solution;