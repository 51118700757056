import React from "react";
import { visions } from "../data.js";
import { v4 as key } from "uuid";

const Vision = () => {

    return (
        <div className="vision">
            <h2 className="vision-heading">The vision</h2>
            <div className="vision-box">
            {visions.map(vision => (
                <div className="vision-item" key={key()}>
                <div>
                <img className="vision-item-image" src={vision.icon} alt="vision icon" />
                <p>{vision.content}</p>
                </div>
                
            </div>
            ))}   
            </div>
        </div>
        
    )
}

export default Vision;