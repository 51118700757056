import React from "react";
import Hero from "./Hero";
import Waitlist from "./Waitlist";
import Problem from "./Problem";
import Vision from "./Vision";
import Solution from "./Solution";
import Footer from "./Footer";


const App = () => {
   
    return (
        <React.Fragment>
            <Hero />
            <div className="waitlist-top">
            <Waitlist />
            </div>
            <Problem />
            <Vision />
            <Solution />
            <Waitlist />
            <Footer />
        </React.Fragment>
        
    )
}

export default App;