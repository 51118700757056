import React from "react";
import Logo from "./Logo";
import Social from "./Social";
import heroImg from "../images/landing-page/hero-img.svg";

// react-svg helps display SVG.
import { ReactSVG } from "react-svg";

const Hero = () => {
    return (
        <div className="hero-container">
            <div className="hero-inner">
                <div className="hero-top">
                    <div className="hero-top-logo">
                        <Logo />
                    </div>


                    <div className="hero-top-social">
                        <Social />
                    </div>
                </div>


                <div className="hero-main">
                    <div className="hero-text">
                        <h1 className="hero-text-header">
                        Simplifying access to health.
                            {/* <br /> */}
                        {/* Find vetted healthcare professionals, and hire for a day or a year! */}
                        </h1>
                        <p className="hero-text-para">
                        Find a medic, hire for a day or a year! 
                        </p>
                    </div>

                    <div class-name="manage-hero-img-size">
                    <ReactSVG
                        src={heroImg}
                        className="hero-img"
                        beforeInjection={(svg) => {
                            svg.classList.add('hero-img')
                        }}
                    />
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Hero;