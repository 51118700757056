import React from "react";
import { FaCopyright } from "react-icons/fa";
import Social from "./Social";
import Sublinks from "./Sublinks";
// import { categories } from "../data";
// import { support } from "../data";
import { about } from "../data";
import logo from "../images/global/logo.png"

const Footer = () => {

    return (
        <footer>
            <div className="footer-inner">
                <div className="footer-brand-area">
                    <div className="footer-logo-area">
                        <img src={logo} alt="logo" className="footer-logo" />
                        <p>Access to health</p>
                    </div>

                    <div className="footer-social-area">
                        <p>Follow us</p>
                        <div className="footer-social-icon">
                            <Social />
                        </div>
                    </div>
                </div>

                <div className="footer-links-area">
                    {/* <Sublinks heading={categories.heading} links={categories.links} /> */}
                    {/* <Sublinks heading={support.heading} links={support.links} /> */}
                    <Sublinks heading={about.heading} links={about.links} />
                </div>
            </div>
            <p className="copyright"> <FaCopyright /> Keer copyright {new Date().getFullYear()}</p>
        </footer>

    )
}

export default Footer;